import React from "react";
import NavBar from "../components/Nav_bar";
import ServiceHeading from "../components/Service_heading";
import Gallary from "../components/Gallary/Gallery";
import { labImgs, poster, posters } from "../assets/LabImg";
import labLogo from "../assets/LabImg/labLogo.jpg";
import Slider from "../components/Slider/Slider";
import About from "../components/Reach";
import Map from "../components/Map/Map";
import Footer from "../components/Footer";
import WhatsAppRenderer from "../components/Whatsapp/Whatsapp";
import { labsAbout } from "../data";
import { useTypewriter } from "react-simple-typewriter";
import { Carousel1 } from "./Carosal";
import { labbsss, labs, labss } from "../assets/LabImg/carosals";


const Labs = () => {
  // const [typewriter] = useTypewriter({
  //   words: ["Welcome to Vcare Lab, where cutting-edge technology and compassionate care come together. As a leading provider of diagnostic services, we are dedicated to offering accurate and reliable results to support your healthcare needs"],
  //   loop: {},
  //   typeSpeed: 50,
  //   deleteSpeed: 40
  // }
  const color = "red";

  return (
    <div className="flex items-center flex-col overflow-x-hidden">
      <NavBar title={"VCARE LABS"} logo={labLogo} />
      <Carousel1 images={labs} image={labss} img={labbsss} />
      <ServiceHeading
        title={"VCARE LABS"}
        description={"Welcome to Vcare Lab, where cutting-edge technology and compassionate care come together. As a leading provider of diagnostic services, we are dedicated to offering accurate and reliable results to support your healthcare needs"}
      />
      <Gallary images={labImgs} post={posters} />
      <ServiceHeading title={"ABOUT US"} />
      <Slider labsAbout={labsAbout} />
      <ServiceHeading title={"CONTACT US"} />
      <About style={color} address={"85 F1,Opp Firdousiya Masjid,Parakkai Road,Kottar, Nagerciol- 629002"}
        phoneNum={"+916385893387, 04652463447 "}
        emailId={"Lab@vcaregroups.com"}
        whatsAppNumber={"+916385893387"} />

      <Map
        iframeSrc={
          "https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d1369.716536004454!2d77.4449749344631!3d8.156378508038312!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zOMKwMDknMjQuNSJOIDc3wrAyNic0NC41IkU!5e0!3m2!1sen!2sin!4v1707410379146!5m2!1sen!2sin"
        }
      />
      <WhatsAppRenderer whatsAppNumber={"+916385893387"} />
      <Footer
        title={"LAHAIROI PVT LIMITED"}
        contact1={"+91 9003856460"}
        // contact2={"04652463447"}
        email={"lahairoipvt@gmail.com"}
        address1={"12/106b"}
        address2={"Mylaudy kanniyakumari"}
      />
    </div>
  );
};

export default Labs;
