import h1 from "./h1.jpg";
import h2 from "./h2.jpg";
import h3 from "./h3.jpg";
import h4 from "./h4.jpg";
// import h5 from "./h5.jpg";
// import h6 from "./h6.jpg";
// import h7 from "./h7.jpg";
// import h8 from "./h8.jpg";
// import h9 from "./h9.jpg";
// import h10 from "./h10.jpg";
// import h11 from "./h11.jpg"
import video2 from './g3.gif'
import video from './g1.gif';
import video1 from './g2.gif'

export const postvedio = [video2, video, video1]

export const HUImgs = [h1, h2, h3, h4];