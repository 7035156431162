import React from "react";
import NavBar from "../components/Nav_bar";
import ServiceHeading from "../components/Service_heading";
import Gallary from "../components/Gallary/Gallery";
import { pharmaImgs, poster } from "../assets/PharImg";
import pharmaLogo from "../assets/PharImg/pharmaLogo.jpg";
import About from "../components/Reach";
import Map from "../components/Map/Map";
import Footer from "../components/Footer";
import WhatsAppRenderer from "../components/Whatsapp/Whatsapp";
import './css/pharma.css'
import { useTypewriter } from "react-simple-typewriter";
import { Carousel1 } from "./Carosal";
import { pham, phams, phamss } from "../assets/PharImg/caroslass/indes";


const Pharma = () => {
  // const [typewriter] = useTypewriter({
  //   words: [""],
  //   loop: {},
  //   typeSpeed: 50,
  //   deleteSpeed: 40
  // })

  const color = "#900C3F"

  return (
    <div className="flex items-center flex-col overflow-x-hidden">
      <NavBar title={"VCARE PHARMA"} logo={pharmaLogo} />
      <Carousel1 images={pham} image={phams} img={phamss} />
      <ServiceHeading
        title={"VCARE PHARMA"}
        description={"We are all in for better health & Where everyone walks out with a good Smile "}
      />
      <Gallary images={pharmaImgs} post={poster} />
      <ServiceHeading title={"ABOUT US"} />
      <div className="about">
        <span className="text-gray-400 description">
          {/* {typewriter} */}
          V Care Pharma is a leading and largest pharmaceutical store. We provide quality prescribed products only We have qualified, Registered well trained pharmacists to help all of you. We are using the Advance system to identify the expiry of medicines before sale and the availability of stock of medicines inour stores.Our Stores have the drugs as per FDA (Food and Drug Administration)norms. We provide a large variety of medicines, Surgical items, and Natural Health Care products in our Stores all over the world...
        </span>
        {/* <span className="text-gray-400 description">
          {typewriters}
        </span> */}
        {/* <span className="text-gray-400 description">

        </span> */}
      </div>
      <ServiceHeading title={"CONTACT US"} />
      <About
        style={color}
        address={"1, hinahana building velladichovilai Parakkai Nagercoil 629601"}
        phoneNum={"+91 9159333386, 04652463446"}
        emailId={"Pharma@vcaregroups.com"}
        whatsAppNumber={"+919159333386"}
      />
      <Map
        iframeSrc={
          "https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3949.4819571828866!2d77.44424837500883!3d8.15409949187642!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zOMKwMDknMTQuOCJOIDc3wrAyNic0OC42IkU!5e0!3m2!1sen!2sin!4v1707468697595!5m2!1sen!2sin"
        }
      />
      <WhatsAppRenderer whatsAppNumber={"+919159333386"} />
      <Footer
        title={"LAHAIROI PVT LIMITED"}
        contact1={"+91 9003856460"}
        // contact2={"04652463447"}
        email={"lahairoipvt@gmail.com"}
        address1={"12/106b"}
        address2={"Mylaudy kanniyakumari"}
      />
    </div>
  );
};

export default Pharma;
