import React from 'react'
import './Gallery.css'
import { Container } from '@mui/material'

const Gallery = ({ images, post }) => {


  return (
    <Container>
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-5">
        {/* First division */}
        <div className="image-gallery">
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-5">
            {/* <h1>Hai</h1> */}
            {post.map((poster, index) => {
              return (<div className="image-container" key={index}>
                <img data-aos="fade-right" src={poster} alt={poster.alt} controls={true} loop={true} playing={true} />
                {/* <ReactPlayer
                  className="video"
                  src={poster} alt={poster.alt}
                  data-aos="fade-right"
                  controls={true}
                  width='100%'
                  height='100%'
                /> */}
              </div>)

            })

            }
          </div>
        </div>
        {/* Second division */}
        <div className='image-gallery' >
          <div className="grid-cols-1 gap-4 sm:grid-cols-2">
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-5">
              {images.map((image, index) => (
                <div className="image-container" key={index}>
                  <img data-aos="fade-left" src={image} alt={image.alt} className="w-full" />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </Container>
  )
}

export default Gallery
