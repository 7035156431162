import React from "react";
import NavBar from "../components/Nav_bar";
import ServiceHeading from "../components/Service_heading";
import Gallary from "../components/Gallary/Gallery";
import { HUImgs, post, postvedio } from "../assets/H&U";
import HULogo from "../assets/H&U/logo2.jpg";
import About from "../components/Reach";
import Map from "../components/Map/Map";
import Footer from "../components/Footer";
import WhatsAppRenderer from "../components/Whatsapp/Whatsapp";
import { HUAbout } from "../data";
import "./css/pharma.css";
import Slider from "../components/Slider/Slider";
import { Carousel1 } from "./Carosal";
import carosal, { car, cars } from "../assets/H&U/Carosal";

const HU = () => {

  const color = "#004225";

  return (
    <div className="flex items-center flex-col overflow-x-hidden">
      <NavBar title={"Vcare Haj and  Umrah "} logo={HULogo} />
      {/* <Carosal images={carosal} /> */}
      <Carousel1 images={carosal} image={car} img={cars} />
      <ServiceHeading
        title={"Vcare Haj and  Umrah "}
        description={"Welcome to Vcare Haj and  Umrah "}
      />
      <Gallary images={HUImgs} post={postvedio} />
      <ServiceHeading title={"ABOUT US"} />
      <Slider labsAbout={HUAbout} />
      <ServiceHeading title={"CONTACT US"} />
      <About
        style={color}
        address={"No 1, hinahana building velladichovilai"}
        phoneNum={"+91 9789511639,  04652463447"}
        emailId={"Lafir.gm@vcaregroups.com"}
        whatsAppNumber={"+919789511639"}
      />
      <Map
        iframeSrc={
          "https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3949.4819571828866!2d77.44424837500883!3d8.15409949187642!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zOMKwMDknMTQuOCJOIDc3wrAyNic0OC42IkU!5e0!3m2!1sen!2sin!4v1707471136344!5m2!1sen!2sin"
        }
      />
      <WhatsAppRenderer whatsAppNumber={"+919789511639"} />
      <Footer
        title={"LAHAIROI PVT LIMITED"}
        contact1={"+91 9003856460"}
        // contact2={"04652463447"}
        email={"lahairoipvt@gmail.com"}
        address1={"12/106b"}
        address2={"Mylaudy kanniyakumari"}
      />
    </div>
  );
};

export default HU;
