import React from 'react';
import { Link } from 'react-router-dom'
import "./Card_Home.css"

const Card_Home = ({ image, description, link }) => {
  return (
    <React.Fragment>
      <section>
        <div data-aos="fade-up" data-aos-duration="375" data-aos-easing="ease-in-out" className="container1 my-3">
          <div className="pl-5 pr-5 mt-[-1rem] flex flex-col items-center text-center ">
            <img className="image" src={image} alt="" />
            <p className="my-3 font-normal text-gray-700">{description}</p>
            <Link to={link} className="button self-center px-6 py-4 text-xl  text-center text-white bg-[#FCC201] mt-2 transition-all ease-in-out duration-300">
              SEE MORE
            </Link>
          </div>
        </div>
      </section>
    </React.Fragment>
  )
}

export default Card_Home