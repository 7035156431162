import lab1 from "./lab1.jpg";
// import lab2 from "./lab2.jpg";
import lab3 from "./lab3.jpg";
// import lab4 from "./lab4.jpg";
// import lab5 from "./lab5.jpg";
// import lab6 from "./lab6.jpg";
// import lab7 from "./lab7.jpg";
// import lab8 from "./lab8.jpg";
import video from "./lab1.gif"

export const posters = [video]


export const labImgs = [lab1, lab3];
