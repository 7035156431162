import React from "react";
import { Link } from "react-router-dom";
import { home } from "../data";
import Card_Home from "../components/Card_Home";
import homeImg from "../assets/logo.png";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useTypewriter } from "react-simple-typewriter";

const Home = () => {
  console.log(home);
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const [typewriter] = useTypewriter({
    words: ["VCARE GROUPS"],
    loop: {},
    typeSpeed: 100,
    deleteSpeed: 40
  })
  return (
    <React.Fragment>
      {/* Not Resuing Hero Section So not making it as component */}
      <section className="hero_section w-screen">
        <div
          className=" h-[48vh] md:h-[50vh] lg:h-[80vh] bg-cover lg:bg-cover lg:bg-no-repeat flex items-center  flex-col rounded-b-[30%] text-white"
          style={{ backgroundImage: "url(img/d8.jpg)" }}
        >
          <div className="w-full h-full flex  items-center flex-col bg-[#4169E1] bg-opacity-75 rounded-b-[30%]">
            <Link to="/">
              <div className="flex items-center flex-col pt-10">
                <img data-aos="flip-left"
                  data-aos-easing="ease-out-cubic"
                  data-aos-duration="2000" className="w-20 md:w-24" src={homeImg} alt="logo" />
                <span className="text-2xl md:text-3xl font-[AltoneTrial-Reg] font-extrabold">
                  {typewriter}
                </span>
                {/* <span className="text-1xl lg:text-3xl text-[#fff] w-[80%] md:w-[50%] lg:w-[74%] " data-aos="fade-right">
                  Our Services Will SucessFully Running 22 Years Of Excellence
                </span> */}
              </div>
            </Link>
            {/* <Nav /> */}
            <div className="flex items-center flex-col">
              <div
                className="font-semibold pt-6 pb-4"
                style={{ textShadow: "rgba(0, 0, 0, 0.514) 0px 3px 6px" }}
              >
                <span className="text-1xl lg:text-4xl text-[#FCC201]">
                  Quality, Innovation & Accessibility
                </span>{" "}
                {/* <span className="text-2xl lg:text-6xl text-[#FCC201]">
                  Pharmacy!
                </span> */}
              </div>
              <div className="text-lg lg:text-2xl w-[80%] md:w-[50%] lg:w-[74%] text-center">
                In the economic world, the V Care groups of
                companies which includes Vcare Pharma, Vcare Labs
                <button
                  onClick={handleClickOpen}
                  style={{
                    color: "#000000",
                    paddingLeft: "10px",
                    fontWeight: "500",
                    cursor: 'pointer'
                  }}
                >
                  Read more..
                </button>
                <Dialog
                  open={open}
                  onClose={handleClose}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <DialogTitle id="alert-dialog-title">
                    {"About VCare Groups"}
                  </DialogTitle>
                  <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                      <div style={{ marginBottom: "1em" }}>
                        In the economic world, the V Care groups of
                        companies which includes Vcare Pharma, Vcare Labs, and
                        Vcare Haj and Umrah Services is definitely something to
                        be reckoned with. With its robust market presence and
                        quick expansion, the Vcare Group has made a name for
                        itself in a number of industrial sectors. The V Care
                        groups is run by a network of group firms, each of which
                        has a specialised area of knowledge. The group's variety
                        guarantees that we remain at the forefront of their
                        respective industries and allows us to meet a wide range
                        of consumer needs.
                      </div>
                      <div style={{ marginBottom: "1em" }}>
                        What sets the V Care groups apart from its competitors
                        is its unwavering commitment to customer satisfaction
                        and safety. Each company under the group's umbrella
                        adheres to strict quality standards and follows ethical
                        business practices. The group prioritizes the well-being
                        of its customers and places their trust and safety above
                        all else.
                      </div>
                      <div style={{ marginBottom: "1em" }}>
                        In addition to its business operations, the V Care
                        groups is also actively involved in corporate social
                        responsibility initiatives. The group believes in giving
                        back to society and is dedicated to making a positive
                        impact on the communities it operates in. Through
                        various philanthropic endeavors, the Vcare Group strives
                        to improve the lives of those in need and create a
                        better future for all.
                      </div>
                      <div>
                        As the V Care groups continues to expand its presence
                        globally, it remains committed to its core values of
                        integrity, excellence, and innovation. By staying true
                        to its mission of providing top-notch products and
                        services, the group is poised to achieve even greater
                        success in the years to come.
                      </div>
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleClose} autoFocus>
                      Close
                    </Button>
                  </DialogActions>
                </Dialog>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="flex flex-col md:flex-row w-full justify-evenly items-center lg:mt-[-32vh]">
        {home.map((card, idx) => {
          return (
            <Card_Home
              key={idx}
              image={card.image}
              description={card.description}
              link={card.link}
            />
          );
        })}
      </div>
    </React.Fragment>
  );
};

export default Home;
