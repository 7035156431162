import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/Home.jsx";
import Labs from "./pages/Labs.jsx";
import Pharma from "./pages/Pharmacy.jsx";
import HU from "./pages/HU.jsx";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route
            path="/pharma"
            element={
              <Pharma />
            }
          />
          <Route
            path="/labs"
            element={
              <Labs />
            }
          />
          <Route
            path="/h&u"
            element={
              <HU />
            }
          />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
