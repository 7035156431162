import React from "react";
import { Link } from "react-router-dom"; // Import Link from react-router-dom
import "./Navbar.css";

const Nav_bar = ({ logo }) => {

  return (
    <React.Fragment>
      <section style={{ position: "fixed", zIndex: "10" }} className="flex justify-center flex-col items-center w-full text-white">
        <div className="w-full h-full flex flex-row justify-between pr-10 pl-10 pt-5 pb-5 items-center bg-[#4169E1] bg-opacity-65">
          <div className="flex items-center logo-container">
            <img className="w-20 md:w-40" src={logo} alt="logo" />
          </div>
          <div className="flex items-center " style={{ gap: "20px" }}>
            {/* Home button */}
            <Link to="/" className="nav-button2 home-button md:text-xl md:p-2">
              Home
            </Link>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

export default Nav_bar;
